import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import VIDEO_MATCHES from '@/assets/images/landing/video-matches.png';

import { ChakraNextResponsiveImage } from '@/components/ui/shared/image/ChakraNextResponsiveImage';

export const LandingOmnisearch = () => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection={{ base: 'column', lg: 'row' }} alignItems="center" justifyContent="space-between">
      <Box mb={{ base: 14, lg: 0 }} w={{ base: 'full', lg: '50%' }}>
        <Heading mb="4" fontSize="3xl">
          {t('newLanding.omnisearchHeading')}
        </Heading>
        <Text fontSize="md">{t('newLanding.omnisearchDescription')}</Text>
      </Box>
      <ChakraNextResponsiveImage
        w={{ base: '335px', lg: '487px' }}
        h={{ base: '292px', lg: '425px' }}
        zIndex={1}
        src={VIDEO_MATCHES}
        alt="video matches"
      />
    </Flex>
  );
};
