import { Box, Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import CONFERENCES_GRADIENT from '@/assets/images/landing/conferences-gradient.png';

import { CoreHeroBody, CoreHeroTitle, CoreHeroWithImage } from '@/components/ui/shared/heroes/CoreHero/CoreHero';

export const LandingHeader: FC = () => {
  const { t } = useTranslation();

  return (
    <CoreHeroWithImage src={CONFERENCES_GRADIENT} bg="black">
      <CoreHeroBody h={{ base: '546px', sm: '485px', md: '546px' }} px={{ base: 5, md: 10, lg: 5 }}>
        <Flex
          py={{ base: 12, lg: 20 }}
          color="white"
          position="absolute"
          top="0"
          h="100%"
          flexDirection="column"
          justifyContent="space-between"
        >
          <CoreHeroTitle p="0" fontSize={{ base: '3xl', lg: '4xl' }}>
            {t('newLanding.headerHeading')}
          </CoreHeroTitle>
          <Box>
            <Text fontFamily="heading" fontWeight="bold" mb="4px" lineHeight={1} fontSize="3xl">
              Underline Science
            </Text>
            <Text fontSize="lg">{t('newLanding.headerDescription')}</Text>
          </Box>
        </Flex>
      </CoreHeroBody>
    </CoreHeroWithImage>
  );
};
