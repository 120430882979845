import { Container, Heading } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const LandingContentHeading: FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Heading fontSize="3xl" my={{ base: 6, lg: 16 }} textAlign="center">
        {t('newLanding.contentHeading')}
      </Heading>
    </Container>
  );
};
