import { forwardRef, HStack, StackProps } from '@chakra-ui/react';
import { __DEV__ } from '@chakra-ui/utils';

export const HScroll = forwardRef<StackProps, 'div'>(function HScroll({ children, ...rest }, ref) {
  return (
    <HStack overflowX="auto" sx={{ overscrollBehaviorX: 'contain', '& > *': { flexShrink: 0 } }} ref={ref} {...rest}>
      {children}
    </HStack>
  );
});

if (__DEV__) {
  HScroll.displayName = 'HScroll';
}
