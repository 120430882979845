import { Flex, Heading } from '@chakra-ui/react';
import { useResources } from 'datx-jsonapi-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { HScroll } from '@/components/ui/core/Scroll/Scroll';
import { Section } from '@/components/ui/core/Section/Section';
import { SpeakerCard } from '@/components/ui/features/landing/LandingProfiles/components/SpeakerCard';
import { landingThinProfilesQuery } from '@/hooks/useProfiles';

export const LandingProfiles: FC = () => {
  const { t } = useTranslation();
  const { data: profiles } = useResources(landingThinProfilesQuery);

  return (
    <Section variant="dimmed" mt={16} mb={10}>
      <Flex w="full" flexDir="column">
        <Heading mb={8}>{t('newLanding.profilesHeading')}</Heading>
        <HScroll mx={-8} p={6} pt="0" pb="8" spacing={1} alignItems="stretch">
          {profiles?.map((profile) => (
            <SpeakerCard key={profile.id} profile={profile} />
          ))}
        </HScroll>
      </Flex>
    </Section>
  );
};
