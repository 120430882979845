import { Heading, Box, Text, Grid, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { OriginalsPromoVideo } from '../../../shared/promo-videos/OriginalsPromoVideo/OriginalsPromoVideo';

export const LandingOriginals: FC = () => {
  const { t } = useTranslation();

  return (
    <Flex mb="12" flexDirection={{ base: 'column-reverse', lg: 'row' }}>
      <Flex mt={{ base: 8, lg: 0 }} justify={{ base: 'center', lg: 'start' }} flex="1">
        <OriginalsPromoVideo />
      </Flex>
      <Box flex="1" alignSelf={{ base: 'self-start', lg: 'center' }}>
        <Heading fontSize="3xl" mb={2}>
          {t('newLanding.originalsHeading')}
        </Heading>
        <Text fontSize="md" lineHeight={7} fontFamily="body">
          {t('newLanding.originalsDescription')}
        </Text>
      </Box>
    </Flex>
  );
};
