import { Box, Button, Flex, Heading, Text, Link } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import DIGITAL_LIBRARY_IMAGE from '@/assets/images/landing/digital-library.png';

import { ChakraNextResponsiveImage } from '@/components/ui/shared/image/ChakraNextResponsiveImage';

export const LandingLectures: FC = () => {
  const { t } = useTranslation();

  return (
    <Flex my={20} flexDirection={{ base: 'column', lg: 'row' }} alignItems="center" justifyContent="space-between">
      <Box flexBasis="100%" maxW={{ base: 'full', lg: '50%' }}>
        <Heading fontSize="3xl" mb="2">
          {t('newLanding.lecturesHeading')}
        </Heading>
        <Text fontSize="md" mb="8">
          {t('newLanding.lecturesDescription')}
        </Text>
        <Flex flexDir="column">
          <Button as={Link} href="/library" w="full" maxW={{ base: 'full', sm: '260px' }} mb="4" size="lg">
            {t('newLanding.lecturesContinueToLecturesButtonLabel')}
          </Button>
          <Button
            as={Link}
            href="/librarians"
            w="full"
            maxW={{ base: 'full', sm: '260px' }}
            size="lg"
            variant="outline"
          >
            {t('newLanding.lecturesMoreInfoOnLibrarian')}
          </Button>
        </Flex>
      </Box>
      <ChakraNextResponsiveImage
        w={{
          base: '352.5px',
          md: '512px',
        }}
        h={{
          base: '250px',
          md: '362px',
        }}
        mt={{ base: 8, lg: 0 }}
        src={DIGITAL_LIBRARY_IMAGE}
        alt="digital library"
      />
    </Flex>
  );
};
