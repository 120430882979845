import { Text, Icon, Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import CommentIcon from '@/assets/icons/ic-comment.svg';
import IconHover from '@/assets/icons/ic-hover.svg';
import PaperIcon from '@/assets/icons/ic-paper.svg';
import QuoteIcon from '@/assets/icons/ic-quote.svg';

import { GlassyInfoCard } from '@/components/ui/features/landing/LandingFeatures/components/GlassyInfoCard';
import { BlurredStain } from '@/components/ui/shared/backgrounds/BlurredStain/BlurredStain';

export const LandingFeatures = () => {
  const { t } = useTranslation();

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      flexDirection={{ base: 'column', lg: 'row' }}
      gap={4}
      my={12}
      position="relative"
    >
      <BlurredStain top={{ base: 0, lg: '-330px' }} right={{ base: 0, lg: '-250px' }} />
      <GlassyInfoCard icon={CommentIcon} heading={t('newLanding.downloadsHeading')}>
        <Text>{t('newLanding.downloadsDescription')}</Text>
      </GlassyInfoCard>
      <GlassyInfoCard icon={PaperIcon} heading={t('newLanding.transcriptCardHeading')}>
        <Text as="div">
          <Text pb={4}>{t('newLanding.transcriptCardDescriptionPart1')}</Text>
          <Text as="span">{t('newLanding.transcriptCardDescriptionPart2')} </Text>
          <Text as="span" pos="relative">
            <Text as="span" bg="violet.500" color="white" p="5px">
              {t('newLanding.transcriptCardDescriptionPart3')}
            </Text>
            <Box as="span" pos="absolute" top="-5px" left="59px">
              <Box
                as="span"
                display="block"
                borderRadius="100px"
                bgColor="white"
                px="10px"
                fontSize="0.75rem"
                lineHeight="25px"
                color="black"
                w="53px"
                h="24px"
                mb={3}
              >
                12:54
              </Box>
              <Icon as={IconHover} ml="20px" />
            </Box>
          </Text>
        </Text>
      </GlassyInfoCard>
      <GlassyInfoCard icon={QuoteIcon} heading={t('newLanding.doiCardHeading')}>
        <Text>{t('newLanding.doiCardDescription')}</Text>
      </GlassyInfoCard>
    </Flex>
  );
};
