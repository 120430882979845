import { Container, Grid } from '@chakra-ui/react';
import NextImage from 'next/image';
import { FC } from 'react';

import AAAI_LOGO from '@/assets/images/logos/aaai-with-text.png';
import AMAZON_SCIENCE from '@/assets/images/logos/amazon-science.png';
import ASME from '@/assets/images/logos/ASME.png';
import GOOGLE_AI from '@/assets/images/logos/google-ai.png';
import IIIE_LOGO from '@/assets/images/logos/ieee.png';
import UKSG from '@/assets/images/logos/UKSG.png';

import { ChakraNextResponsiveImage } from '@/components/ui/shared/image/ChakraNextResponsiveImage';

export const LandingPartnersList: FC = () => {
  return (
    <Container>
      <Grid
        gridTemplateColumns={{
          base: '1fr 1fr',
          md: '1fr 1fr 1fr',
          lg: `repeat(6, max-content)`,
        }}
        gap="32px"
        mt="12"
        px={{ base: 2, md: 16, lg: 0 }}
        alignItems="center"
        justifyContent="space-around"
        justifyItems="center"
      >
        <NextImage width={125} src={IIIE_LOGO} alt="IEEE" />
        <NextImage width={140} src={AAAI_LOGO} alt="AAAI" />
        <ChakraNextResponsiveImage
          w={{ base: '123px', lg: '200px' }}
          h={{ base: '47px', lg: '76px' }}
          src={AMAZON_SCIENCE}
          alt="Amazon science"
        />
        <NextImage width={120} src={UKSG} alt="UKSG" />
        <NextImage width={135} src={ASME} alt="ASME" />
        <NextImage width={180} src={GOOGLE_AI} alt="Google AI" />
      </Grid>
    </Container>
  );
};
