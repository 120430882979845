import { Container } from '@chakra-ui/react';
import { GetStaticProps, NextPage } from 'next';
import { NextSeo } from 'next-seo';
import React, { Fragment } from 'react';

import { Section } from '@/components/ui/core/Section/Section';
import { LandingContentHeading } from '@/components/ui/features/landing/LandingContentHeading/LandingContentHeading';
import { LandingFeatures } from '@/components/ui/features/landing/LandingFeatures/LandingFeatures';
import { LandingHeader } from '@/components/ui/features/landing/LandingHeader/LandingHeader';
import { LandingLectures } from '@/components/ui/features/landing/LandingLectures/LandingLectures';
import { LandingOmnisearch } from '@/components/ui/features/landing/LandingOmnisearch/LandingOmnisearch';
import { LandingFreeTrial } from '@/components/ui/features/landing/LandingOrganizingEvents/LandingFreeTrial';
import { LandingOriginals } from '@/components/ui/features/landing/LandingOriginals/LandingOriginals';
import { LandingPartnersList } from '@/components/ui/features/landing/LandingPartnersList/LandingPartnersList';
import { LandingProfiles } from '@/components/ui/features/landing/LandingProfiles/LandingProfiles';
import { LandingSubjects } from '@/components/ui/features/landing/LandingSubjects/LandingSubjects';
import { Layout } from '@/components/ui/shared/layouts/Layout/Layout';
import { landingThinProfilesQuery } from '@/hooks/useProfiles';
import { rootSubjectsQuery } from '@/hooks/useSubjects';
import { landingThinLecturesQuery } from '@/hooks/useThinLectures';
import { generateStore } from '@/utils/generateStore';

const LibraryLandingPage: NextPage = () => {
  return (
    <Fragment>
      <NextSeo
        titleTemplate="%s"
        title="Underline Science | Access Academic Videos & Scientific Conferences Online"
        description="Underline Science is your on-demand video platform for accessing academic videos and global conferences, offering the latest scientific research in your field."
        openGraph={{
          url: `${process.env.NEXT_PUBLIC_BASE_URL}`,
        }}
      />
      <Layout>
        <LandingHeader />
        <Section overflow="hidden" variant="dimmed">
          <Container>
            <LandingContentHeading />
            <LandingPartnersList />
            <LandingLectures />
            <LandingOriginals />
            <LandingOmnisearch />
            <LandingFeatures />
            <LandingSubjects />
            <LandingProfiles />
          </Container>
        </Section>
        <LandingFreeTrial />
      </Layout>
    </Fragment>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const store = generateStore();

  try {
    await store.fetchResources(rootSubjectsQuery(4));
    await store.fetchResources(landingThinLecturesQuery);
    await store.fetchResources(landingThinProfilesQuery);
  } catch (err) {
    return { notFound: true };
  }
  return {
    props: {
      fallback: store.fallback,
    },
    revalidate: 120,
  };
};

export default LibraryLandingPage;
