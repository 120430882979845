import { QueryResources } from '@/lib/datx-jsonapi-react';
import { ThinProfile } from '@/resources/ThinProfile';

export const landingThinProfilesQuery: QueryResources<ThinProfile> = [
  ThinProfile,
  {
    filter: {
      library_featured: 'true',
    },
    params: [
      {
        key: 'page[size]',
        value: '5',
      },
      {
        key: 'page[number]',
        value: '1',
      },
    ],
  },
];
