import { QueryResources } from '@/lib/datx-jsonapi-react';
import { ThinSubject } from '@/resources/ThinSubject';

export const rootSubjectsQuery = (pageSize = 100): QueryResources<ThinSubject> => [
  ThinSubject,
  {
    filter: {
      scope: 'roots',
    },
    params: [
      {
        key: 'page[size]',
        value: `${pageSize}`,
      },
    ],
  },
];
