import { Card, CardBody, Flex, Heading, Icon } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
interface IGlassyCardProps {
  children: ReactNode;
  heading: string;
  icon: any;
}

export const GlassyInfoCard: FC<IGlassyCardProps> = ({ children, heading, icon }) => {
  return (
    <Card variant="glass" borderRadius="15px" maxW={{ base: '498px', lg: '354px' }} w="full">
      <CardBody px={7} py={6}>
        <Flex mb={4} alignItems="center">
          <Icon mr={5} as={icon} h="1.5rem" w="1.5rem" color="violet.500" />
          <Heading fontSize="xl">{heading}</Heading>
        </Flex>
        {children}
      </CardBody>
    </Card>
  );
};
