import { Flex, HTMLChakraProps, Text, Box } from '@chakra-ui/react';
import Image from 'next/image';
import NextLink from 'next/link';
import { FC } from 'react';

import { ChakraNextResponsiveImage } from '@/components/ui/shared/image/ChakraNextResponsiveImage';
import { LibraryFilter } from '@/enums/LibraryFilter';
import { ThinSubject } from '@/resources/ThinSubject';

export interface ISubjectCardProps extends HTMLChakraProps<'a'> {
  subject: ThinSubject;
}

export const SubjectCard: FC<ISubjectCardProps> = ({ subject }) => {
  const { id, name, mediumHeroImageUrl } = subject;

  return (
    <Flex
      as={NextLink}
      href={`/library/search?${LibraryFilter.Subject}=${id}`}
      position="relative"
      p={4}
      alignItems="end"
      w={{ base: '378px', md: '332px', lg: '287px' }}
      h={{ base: '162px', md: '189px', lg: '162px' }}
      borderRadius="20px"
    >
      <ChakraNextResponsiveImage
        borderRadius="20px"
        overflow="hidden"
        tabIndex={-1}
        src={mediumHeroImageUrl}
        objectFit="cover"
        w={{ base: '335px', md: '332px', lg: '287px' }}
        h={{ base: '162px', md: '189px', lg: '162px' }}
        alt={`background image for ${name}`}
        position="absolute"
        aria-label="hello"
        top="0"
        left="0"
        right="0"
      />
      <Box
        borderRadius="20px"
        tabIndex={-1}
        backgroundImage="linear-gradient(90deg, rgba(0, 0, 0, 0.5) 23.96%, rgba(0, 0, 0, 0) 100%);"
        position="absolute"
        top="0"
        left="0"
        bottom="0"
        right="0"
      />
      <Text
        _groupHover={{ textDecoration: 'underline' }}
        fontFamily="heading"
        fontWeight="bold"
        fontSize="2xl"
        zIndex={1}
        color="white"
      >
        {name}
      </Text>
    </Flex>
  );
};
