import { Box, Flex, Heading } from '@chakra-ui/react';
import { useResources } from 'datx-jsonapi-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SubjectCard } from '@/components/ui/shared/subject/SubjectCard/SubjectCard';
import { rootSubjectsQuery } from '@/hooks/useSubjects';

export const LandingSubjects: FC = () => {
  const { t } = useTranslation();
  const { data: subjects } = useResources(rootSubjectsQuery(4));

  return (
    <Box>
      <Heading color="black" mb={8}>
        {t('newLanding.subjectsTitle')}
      </Heading>
      <Flex justifyContent="flex-start" flexWrap="wrap" gap={4}>
        {subjects?.map((subject) => (
          <SubjectCard key={subject.id} subject={subject} />
        ))}
      </Flex>
    </Box>
  );
};
