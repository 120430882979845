import { Text, Flex, Heading } from '@chakra-ui/react';
import NextLink from 'next/link';
import { FC } from 'react';

import { ChakraNextResponsiveImage } from '@/components/ui/shared/image/ChakraNextResponsiveImage';
import { ThinProfile } from '@/resources/ThinProfile';

export const SpeakerCard: FC<{ profile: ThinProfile }> = ({ profile }) => {
  return (
    <Flex
      as={NextLink}
      href={`/speakers/${profile.linkSlug}`}
      passHref
      textAlign="center"
      alignItems="center"
      p="5"
      flexDir="column"
      w="240px"
      boxSizing="border-box"
      _hover={{
        boxShadow: 'card2xl',
      }}
      cursor="pointer"
    >
      <ChakraNextResponsiveImage
        borderRadius="full"
        overflow="hidden"
        w="200px"
        h="200px"
        src={profile.squareAvatar}
        alt={profile.firstName}
      />
      <Heading fontSize="2xl" mt="9">
        {profile.fullName}
      </Heading>
      {profile.institution && (
        <Text fontSize="sm" color="gray.800" mt="2">
          {profile.institution}
        </Text>
      )}
    </Flex>
  );
};
